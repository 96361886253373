import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Box, Link } from "@material-ui/core"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Box
    display="flex"
    flexDirection="column"
    flex={1}
    height="100vh"
    alignItems="center"
    justifyContent="center"
  >
    <h1>Page not found</h1>
    <p>
      Return to{" "}
      <Link component={GatsbyLink} to="/">
        Home
      </Link>
    </p>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
  </Box>
)

export default NotFoundPage
